<template>
  <v-row class="view-field-client-contract" :class="{ 'view-field-client-contract--archived': isArchived }">
    <v-col cols="12" :md="accessToRooms ? '2' : '3'" class="py-0">
      <view-field :label="$t('client.project')">
        <p class="text-truncate mb-0">{{ project.name }}</p>
      </view-field>
    </v-col>

    <v-col cols="12" md="1" class="py-0">
      <view-field :label="$t('client.type')">
        <p class="text-truncate mb-0">{{ clientType }}</p>
      </view-field>
    </v-col>

    <v-col cols="12" :md="accessToRooms ? '5' : '4'" class="py-0">
      <v-row no-gutters>
        <v-col cols="12" :md="accessToRooms ? '4' : '6'" class="py-0">
          <view-field :label="$t('client.building')">
            <p class="text-truncate mb-0">{{ building.name }}</p>
          </view-field>
        </v-col>

        <v-col cols="12" :md="accessToRooms ? '4' : '6'" class="py-0">
          <view-field :label="$t('client.unit')">
            <router-link :to="unitLink">
              <p class="text-truncate mb-0">{{ unit.name }}</p>
            </router-link>
          </view-field>
        </v-col>

        <v-col v-if="accessToRooms" cols="12" md="4" class="py-0">
          <view-field :label="$t('client.room')">
            <router-link v-if="roomLink" :to="roomLink">
              <p class="text-truncate mb-0">{{ room?.name }}</p>
            </router-link>
            <span v-else>-</span>
          </view-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="2" class="py-0 mb-4 mb-md-0">
      <view-field :label="$t(contractPeriodLabel)">
        <contract-period
          :rental-period-end-date="rentalPeriodEndDate"
          :rental-period-start-date="rentalPeriodStartDate"
          :sales-contract-date="salesContractDate"
        />
      </view-field>
    </v-col>

    <v-col v-if="displayActions" cols="0" md="2" class="d-flex align-center">
      <div class="view-field-client-contract__actions d-flex align-center flex-grow-1 justify-end">
        <v-btn
          v-if="contractIsModified"
          :to="linkToUsage"
          secondary
          class="view-field-client-contract__action primary--text mr-2"
          elevation="0"
          color="secondary"
        >
          <v-icon>mdi-file-document-outline</v-icon>
        </v-btn>

        <v-menu v-else offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              secondary
              class="view-field-client-contract__action primary--text mr-2"
              elevation="0"
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group v-if="isOwner">
              <form-modal
                v-for="documentType in ownerDocumentsList"
                :key="documentType.id"
                :title="documentType.modalTitle"
                :schema="documentType.schema"
                :initial-data="$options.createModelData(documentType.schema)"
                :async="true"
                @submit="saveDocument($event, documentType.type)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title>
                      {{ $t(documentType.text) }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #footer="{ valid }">
                  <v-btn
                    :disabled="!valid"
                    :loading="submitContractLoading"
                    type="submit"
                    elevation="0"
                    color="primary"
                    class="mt-2"
                  >
                    {{ $t('button.add') }}
                  </v-btn>
                </template>
              </form-modal>
              <v-list-item tag="label">
                <v-list-item-title>
                  <input type="file" style="display: none" @input="addFile($event.target.files, 'dtcmPermitScan')" />
                  {{ $t('usage.DTSM_permit') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item tag="label">
                <v-list-item-title>
                  <input
                    type="file"
                    style="display: none"
                    @input="addFile($event.target.files, 'propertyTitleDeedScan')"
                  />
                  {{ $t('usage.property_title_deed') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-else>
              <form-modal
                v-for="documentType in tenantDocumentsList"
                :key="documentType.id"
                :title="documentType.modalTitle"
                :schema="documentType.schema"
                :initial-data="$options.createModelData(documentType.schema)"
                :async="true"
                @submit="saveDocument($event, documentType.type)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title>
                      {{ $t(documentType.text) }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #footer="{ valid }">
                  <v-btn
                    :disabled="!valid"
                    :loading="submitContractLoading"
                    type="submit"
                    elevation="0"
                    color="primary"
                    class="mt-2"
                  >
                    {{ $t('button.add') }}
                  </v-btn>
                </template>
              </form-modal>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <form-modal
          :title="$t('contracts.edit.edit')"
          :schema="$options.schema"
          :initial-data="initialData"
          :async="true"
          @submit="saveContract"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              secondary
              class="view-field-client-contract__action primary--text mr-2"
              elevation="0"
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template #footer="{ valid }">
            <v-btn
              :disabled="!valid"
              :loading="submitContractLoading"
              type="submit"
              elevation="0"
              color="primary"
              class="mt-2"
            >
              {{ $t('button.edit') }}
            </v-btn>
          </template>
        </form-modal>

        <v-btn
          secondary
          :loading="isLoading"
          class="view-field-client-contract__action primary--text"
          elevation="0"
          color="secondary"
          @click="archive"
        >
          <v-icon>mdi-archive</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import analyticsService from '@/services/analytics';
import usersService from '@/services/users';
import notificationService from '@/services/notification';
import mediaService from '@/services/media';
import { isEmptyObject } from '@/utils/common';
import { mapGetters } from 'vuex';

import { createModelData } from '@/schemas/createModelData';

// Constants
import { EDIT_CONTRACT } from '@/constants/analyticsActions';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

import ViewField from '@/components/ViewField.vue';

import {
  CONTRACTS_DETAILED,
  CLIENTS_USAGE_DETAILED,
  CLIENTS_DETAILED,
  UNITS_DETAILED,
  ROOMS_DETAILED,
} from '@/constants/routes';
import viewBuilderService from '@/services/viewBuilder';
import ContractPeriod from '@/components/ContractPeriod.vue';
import { OWNER } from '@/constants/typesClients';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

// Schema
import {
  schema,
  contractWithLandlordsSchema,
  propertyManagmentLetterSchema,
  rentalAgreementSchema,
  coTenantSchema,
} from '@/schemas/contract.schema';

import FormModal from '@/components/schema/FormModal.vue';

export default {
  name: 'ViewFieldClientContract',

  components: { ViewField, ContractPeriod, FormModal },

  props: {
    usageId: { type: Number, required: true },
    status: { type: String, required: true },
    client: { type: Object, required: true },
    clientType: { type: String, required: true },
    project: { type: Object, required: true },
    building: { type: Object, required: true },
    unit: { type: Object, required: true },
    room: { type: Object, default: null },
    rentalPeriodEndDate: { type: String, default: '' },
    rentalPeriodStartDate: { type: String, default: '' },
    salesContractDate: { type: String, default: '' },
    isArchived: { type: Boolean, default: false },
    unitId: { type: Number, required: true },
    contractIsModified: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      submitContractLoading: false,
      tenantDocumentsList: [
        {
          id: 1,
          text: 'usage.rental_agreement',
          modalTitle: 'usage.add_rental_agreement',
          schema: rentalAgreementSchema,
          type: 'rentalAgreement',
        },
        {
          id: 2,
          text: 'usage.co-tenant',
          modalTitle: 'usage.add_co-tenant',
          schema: coTenantSchema,
          type: 'coTenant',
        },
      ],
      ownerDocumentsList: [
        {
          id: 1,
          text: 'usage.contract_with_landlords',
          modalTitle: 'usage.add_contract_with_landlords',
          schema: contractWithLandlordsSchema,
          type: 'contractWithLandlord',
        },
        {
          id: 2,
          text: 'usage.property_management_letter',
          modalTitle: 'usage.add_property_management_letter',
          schema: propertyManagmentLetterSchema,
          type: 'propertyManagementLetter',
        },
      ],
    };
  },

  computed: {
    contractRoute() {
      return {
        name: CONTRACTS_DETAILED,
        params: { id: this.usageId },
        query: { pageName: CLIENTS_DETAILED, id: this.unitId },
      };
    },

    linkToUsage() {
      return { name: CLIENTS_USAGE_DETAILED, params: { clientId: this.client.id, usageId: this.usageId } };
    },

    displayActions() {
      return !this.isArchived && this.$can(UPDATE, subjects.CONTRACTS);
    },

    contractPeriodLabel() {
      return this.clientType === OWNER ? 'client.sales_contract_date' : 'client.rent';
    },

    initialData() {
      return {
        usageId: this.usageId,
        status: this.status,
        client: this.client,
        clientType: this.clientType,
        project: this.project,
        building: this.building,
        unit: this.unit,
        room: this.room,
        rentalPeriod: `${this.rentalPeriodStartDate} - ${this.rentalPeriodEndDate}` || null,
        salesContractDate: this.salesContractDate,
      };
    },

    isOwner() {
      return this.clientType === OWNER;
    },

    unitLink() {
      return { name: UNITS_DETAILED, params: { id: this.unit?.id } };
    },

    roomLink() {
      return this.room && { name: ROOMS_DETAILED, params: { id: this.room?.id } };
    },

    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
  },

  methods: {
    archive() {
      this.isLoading = true;

      usersService
        .archiveUsages({
          usages: [this.usageId],
          isArchived: true,
        })
        .then(() => {
          viewBuilderService.notify('archive-usage');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveContract([usage, closeModal]) {
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.submitContractLoading = true;
      usersService
        .updateUsageById(usage, usage.usageId)
        .then(() => {
          const message = this.$t('contracts.contracts_edited');
          notificationService.success(message, 2000);

          analyticsService.track(EDIT_CONTRACT);
          closeModal();
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.values(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.submitContractLoading = false;
        });
    },

    addFile(files, documentType) {
      const bodyRequest = {};
      const file = files[0];
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.addEventListener('load', async () => {
        mediaService.uploadImage(file).then(tempFileData => {
          bodyRequest[documentType] = tempFileData.id;

          usersService.saveDocuments(bodyRequest, this.usageId).then(() => {
            this.$router.push(this.linkToUsage);
          });
        });
      });
    },

    saveDocument([data, closeModal], documentType) {
      const bodyRequest = {};

      switch (documentType) {
        case 'rentalAgreement':
          bodyRequest.title = data.title;
          bodyRequest.number = data.number;
          bodyRequest.typeContract = data.typeContract;
          bodyRequest.opportunity = data.opportunity;
          bodyRequest.history = data.history;
          bodyRequest.contractScan = data.contractScan[0]?.backgroundFileId || undefined;
          break;
        case 'coTenant':
          bodyRequest.coTenantName = data.coTenantName;
          bodyRequest.coTenantId = data.coTenantId;
          bodyRequest.coTenantIdScan = data.coTenantIdScan[0]?.backgroundFileId || undefined;
          break;
        case 'contractWithLandlord':
          bodyRequest.title = data.title;
          bodyRequest.workModel = data.workModel;
          bodyRequest.startDate = data.startDate || null;
          bodyRequest.endDate = data.endDate || null;
          bodyRequest.contractWithClientScan = data.contractWithClientScan[0]?.backgroundFileId || undefined;
          break;
        case 'propertyManagementLetter':
          bodyRequest.pmlStartDate = data.pmlStartDate || null;
          bodyRequest.pmlEndDate = data.pmlEndDate || null;
          bodyRequest.propertyManagementLetterScan =
            data.propertyManagementLetterScan[0]?.backgroundFileId || undefined;
          break;
        default:
          break;
      }

      if (isEmptyObject(bodyRequest)) {
        closeModal();
        return;
      }

      usersService.saveDocuments(bodyRequest, this.usageId).then(() => {
        closeModal();
        this.$router.push(this.linkToUsage);
      });
    },
  },

  schema,
  notificationItem: null,
  createModelData,
};
</script>

<style lang="scss">
.view-field-client-contract {
  $action-button-width: 36px;
  position: relative;
  border-bottom: 1px solid rgba($--blue-color-20, 0.1);

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    border-bottom: none;
  }

  &--archived {
    opacity: 0.6;
  }

  &__action {
    width: $action-button-width !important;
    height: $action-button-width !important;
    min-width: $action-button-width !important;
    min-height: $action-button-width !important;
    padding: 6px !important;

    &:not(:hover):not(:focus)::before {
      opacity: 0.08 !important;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 12px;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      position: initial;
    }
  }
}
</style>
