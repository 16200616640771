import * as serializers from '@/constants/serializer';
import * as comparators from '@/constants/comparators';
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const linkedProperties = {
  STATUS: 'status',
};

const dependentProperties = {
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  ROOM: 'room',
  USAGES: 'usages',
  CLIENT_TYPE: 'clientType',
  SALES_CONTRACT_DATE: 'salesContractDate',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.CLIENT,
        disabled: true,
        prop: 'client',
        label: 'contracts.edit.client',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        disabled: true,
        prop: 'clientType',
        label: 'contracts.edit.client_type',
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'client.project',
        type: schemaItemTypes.PROJECT,
        prop: dependentProperties.PROJECT,
        rules: [rules.REQUIRED],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: [dependentProperties.BUILDING],
          },
        ],
      },
      {
        type: schemaItemTypes.BUILDING,
        label: 'client.building',
        prop: dependentProperties.BUILDING,
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'projectId',
            from: [dependentProperties.PROJECT, 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: [dependentProperties.PROJECT],
            from: 'project',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: [dependentProperties.UNIT],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.UNIT,
        label: 'client.unit',
        prop: dependentProperties.UNIT,
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'projectId',
            from: [dependentProperties.PROJECT, 'id'],
          },
          {
            param: 'buildingId',
            from: [dependentProperties.BUILDING, 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: [dependentProperties.BUILDING],
            from: 'building',
          },
        ],
      },
      {
        type: schemaItemTypes.ROOM,
        label: 'label.room',
        prop: dependentProperties.ROOM,
        access: ['rooms'],
        payload: [
          {
            param: 'unitId',
            from: [dependentProperties.UNIT, 'id'],
          },
          {
            param: 'buildingId',
            from: [dependentProperties.BUILDING, 'id'],
          },
          {
            param: 'projectId',
            from: [dependentProperties.PROJECT, 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: [dependentProperties.UNIT],
            from: 'unit',
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'contracts.edit.rent_period',
    prop: 'rentalPeriod',
    multiple: true,
    tooltip: {
      text: 'contract.period_tooltip',
      icon: 'mdi-information',
    },
    payload: [
      {
        param: 'multiple',
        from: [linkedProperties.STATUS],
        serializer: serializers.IS_RENTED,
      },
    ],
    displayConditions: {
      variables: [
        {
          from: ['clientType'],
          comparableValues: ['tenant'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `clientType`,
    },
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'salesContractDate',
    label: 'client.sales_contract_date',
    rules: [rules.REQUIRED],
    tooltip: {
      text: 'unit.sales_contract_date_tooltip',
      icon: 'mdi-information',
    },
    displayConditions: {
      variables: [
        {
          from: ['clientType'],
          comparableValues: ['owner'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `clientType`,
    },
  },
];

export const rentalAgreementSchema = [
  {
    type: schemaItemTypes.STRING,
    prop: 'title',
    label: 'label.title',
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        prop: 'number',
        label: 'label.contract_number',
      },
      {
        type: schemaItemTypes.STRING,
        prop: 'typeContract',
        label: 'label.type_of_contract',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.NUMBER,
        prop: 'opportunity',
        label: 'label.opportunity',
      },
      {
        type: schemaItemTypes.STRING,
        prop: 'history',
        label: 'label.contract_history',
      },
    ],
  },
  {
    prop: 'contractScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    removeLabelSuffix: true,
    editable: true,
    onlyOne: true,
  },
];

export const coTenantSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        prop: 'coTenantName',
        label: 'label.co_tenant_name',
      },
      {
        type: schemaItemTypes.STRING,
        prop: 'coTenantId',
        label: 'label.co_tenant_id',
      },
    ],
  },
  {
    prop: 'coTenantIdScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    removeLabelSuffix: true,
    editable: true,
    onlyOne: true,
  },
];

export const contractWithLandlordsSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        prop: 'title',
        label: 'label.title',
      },
      {
        type: schemaItemTypes.STRING,
        prop: 'workModel',
        label: 'label.work_model',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        label: 'label.contract_start_date',
        prop: 'startDate',
        clearable: true,
        payload: [
          {
            param: 'maxDate',
            from: ['endDate'],
          },
        ],
      },
      {
        type: schemaItemTypes.DATE,
        label: 'label.contract_end_date',
        prop: 'endDate',
        clearable: true,
        payload: [
          {
            param: 'minDate',
            from: ['startDate'],
          },
        ],
      },
    ],
  },
  {
    prop: 'contractWithClientScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    removeLabelSuffix: true,
    editable: true,
    onlyOne: true,
  },
];

export const propertyManagmentLetterSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        label: 'label.contract_start_date',
        prop: 'pmlStartDate',
        clearable: true,
        payload: [
          {
            param: 'maxDate',
            from: ['pmlEndDate'],
          },
        ],
      },
      {
        type: schemaItemTypes.DATE,
        label: 'label.contract_end_date',
        prop: 'pmlEndDate',
        clearable: true,
        payload: [
          {
            param: 'minDate',
            from: ['pmlStartDate'],
          },
        ],
      },
    ],
  },
  {
    prop: 'propertyManagementLetterScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    removeLabelSuffix: true,
    editable: true,
    onlyOne: true,
  },
];

export const dtsmPermitSchema = [
  {
    prop: 'dtcmPermitScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    removeLabelSuffix: true,
    editable: true,
    onlyOne: true,
  },
];

export const propertyTitleDeedSchema = [
  {
    prop: 'propertyTitleDeedScan',
    type: schemaItemTypes.FILE_LIST,
    uploadType: 'image',
    removeLabelSuffix: true,
    editable: true,
    onlyOne: true,
  },
];

export default schema;
